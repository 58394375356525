
import { IonTabBar, IonTabButton, IonLabel, IonIcon, IonTabs, IonPage } from '@ionic/vue';
import { grid, logoYen, calendarNumber } from 'ionicons/icons';
import {trTabs} from '@/i18n/messages';
import TimelineTab from '@/views/TimelineTab.vue';
import {defineComponent} from "vue";
export default defineComponent({
  name: 'Tabs',
  components: { IonLabel, IonTabBar, IonTabButton, IonIcon, IonTabs, IonPage},
  setup() {
    return {
      grid,
      logoYen,
      calendarNumber,
      trTabs,
      TimelineTab
    }
  }
})
